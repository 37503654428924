<template>
  <button
    class="no-style-button locales"
    v-for="locale in $i18n.availableLocales"
    v-show="locale !== $i18n.locale"
    @click="changeLocale"
    aria-label="Change Locale"
  >
    <span>
      {{ locale }}
    </span>
  </button>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import type { User } from '@/types/Types'
import { useI18n } from 'vue-i18n'
import authService from '@/stores/auth'
import { getAdditionnalUserInfo } from '@/stores/users'
import { useRouter } from 'vue-router'

const { locale } = useI18n()
const currentUser = ref()
const user = ref<User | null>()
const isSignedIn = ref(false)
const router = useRouter()

function changeLocale(event: MouseEvent) {
  const target = event.target as HTMLElement
  const spanElement = target.querySelector('span')
  if (spanElement) {
    const range = document.createRange()
    range.selectNodeContents(spanElement)

    const selection = window.getSelection()
    selection?.removeAllRanges()
    selection?.addRange(range)
  }

  const newLocale = target.innerText.toLowerCase()
  locale.value = newLocale
  localStorage.setItem('locale', newLocale)
}

function getLocale() {
  if (user.value?.preferredLanguage) {
    locale.value = user.value.preferredLanguage
    localStorage.setItem('locale', locale.value)
  } else {
    const localLocale = localStorage.getItem('locale')
    if (localLocale) locale.value = localLocale
  }
}

async function validateUserIsSignedIn() {
  const googleUser = await authService.getSignedInUser()
  if (googleUser) {
    currentUser.value = googleUser
    user.value = await getAdditionnalUserInfo(googleUser.uid)

    isSignedIn.value = true
  } else {
    isSignedIn.value = false
    router.push({ name: 'home' })
  }
}

onMounted(async () => {
  await validateUserIsSignedIn()
  getLocale()
})
</script>

<style scoped lang="scss">
@import './Locales.scss';
</style>
