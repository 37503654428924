import { getDoc, doc, updateDoc, collection, getDocs, DocumentReference } from 'firebase/firestore'
import { db } from '@/main'
import type { User } from '@/types/Types'
import { BASE_AVATARS, TEAM_BOULDER_AVATARS, TEAM_BOULDER_PACK_ID } from '@/types/Constants'
import { deleteImageFromBucket } from './methods'
import authService from '@/stores/auth'

export async function getAdditionnalUserInfo(userID: string) {
  const updatedUser = (await getDoc(doc(db, 'users', userID))).data() as User
  updatedUser.id = userID
  return updatedUser
}

export async function assignGameToUser(userId: string, gameId: string) {
  await updateDoc(doc(db, 'users', userId), {
    currentGameId: gameId
  })
}

export async function changeUserImage(
  userId: string,
  image: string,
  previousImage: string | undefined = ''
) {
  if (previousImage) {
    await deleteImageFromBucket(previousImage)
  }
  await updateDoc(doc(db, 'users', userId), {
    photoURL: image
  })
}

export async function changeUserEmail(userId: string, email: string) {
  await updateDoc(doc(db, 'users', userId), {
    email: email
  })
}

export async function changeUserDisplayName(userId: string, displayName: string) {
  await updateDoc(doc(db, 'users', userId), {
    displayName: displayName
  })
}

export async function toggleEmailList(userId: string, emailList: boolean) {
  await updateDoc(doc(db, 'users', userId), {
    emailList: emailList
  })
}

export async function changeUserLanguage(userId: string, language: string) {
  await updateDoc(doc(db, 'users', userId), {
    preferredLanguage: language
  })
}

export async function acceptDisclaimer(userId: string) {
  await updateDoc(doc(db, 'users', userId), {
    disclaimerAccepted: true,
    disclaimerAcceptedDate: new Date()
  })
}

export async function updateCategoryBlacklist(userId: string, blacklist: string[]) {
  await updateDoc(doc(db, 'users', userId), {
    categoryBlacklist: blacklist
  })
}

export async function addCartIdToUser(userId: string, cartId: string) {
  await updateDoc(doc(db, 'users', userId), {
    cartId: cartId
  })
}

function getPackIdFromProductId(productId: string) {
  switch (productId) {
    case 'prod_Pz82htLRfSXznX':
      return TEAM_BOULDER_PACK_ID
    case 'prod_Pz7tuW8zrNB51x':
      return TEAM_BOULDER_PACK_ID
    case 'prod_QYMxV4T88tbHBd':
      return TEAM_BOULDER_PACK_ID
    default:
      return ''
  }
}

function checkIfPackIsAlreadyOwned(user: User, packRef: DocumentReference) {
  return user.packs.some((pack) => pack.id === packRef.id)
}

function getPackIdsForSuccess(packs: DocumentReference[]) {
  return packs.map((pack) => {
    return pack.id
  })
}

export async function addPacksToUser() {
  try {
    let packIds: any = []
    const user = await authService.getSignedInUser()
    if (user) {
      const dbUser = await getAdditionnalUserInfo(user.uid)

      if (!dbUser.id) {
        throw new Error('User ID is undefined')
      }

      const userPaymentsRef = collection(db, 'users', user.uid, 'payments')
      const payments = await getDocs(userPaymentsRef)

      for (const paymentDoc of payments.docs) {
        const items = paymentDoc.data().items

        for (const item of items) {
          const productId = item.price.product
          const packId = getPackIdFromProductId(productId)
          const packRef = doc(db, 'packs', packId)
          if (packId && !checkIfPackIsAlreadyOwned(dbUser, packRef)) {
            dbUser.packs.push(packRef)
            packIds = dbUser.packs
            const userDocRef = doc(db, 'users', dbUser.id)
            await updateDoc(userDocRef, {
              packs: dbUser.packs
            })
          }
        }
      }
    }

    return getPackIdsForSuccess(packIds)
  } catch (error) {
    console.error('Error adding pack to user: ', error)
    return []
  }
}

export async function checkAvailableAvatars() {
  let availableAvatars = BASE_AVATARS
  const user = await authService.getSignedInUser()
  if (user) {
    const dbUser = await getAdditionnalUserInfo(user.uid)

    if (!dbUser.id) throw new Error('User ID is undefined')

    if (dbUser.packs.some((pack) => pack.id === TEAM_BOULDER_PACK_ID))
      availableAvatars = availableAvatars.concat(TEAM_BOULDER_AVATARS)

    return availableAvatars
  }
}
