import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, type User } from 'firebase/auth'
import { getAdditionnalUserInfo } from '@/stores/users'

import authRoutes from './authRoutes'
import adminRoutes from './adminRoutes'
import websiteRoutes from './websiteRoutes'
import gameRoutes from './gameRoutes'
import errorRoutes from './errorRoutes'

const routes = [...authRoutes, ...adminRoutes, ...websiteRoutes, ...gameRoutes, ...errorRoutes]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 95,
        behavior: 'smooth'
      }
    }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const auth = getAuth()
  const user: User | null = await new Promise((resolve) => auth.onAuthStateChanged(resolve))

  if (!user) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      next({ name: 'sign-in' })
      return
    }
    next()
    return
  }
  const idTokenResult = await user.getIdTokenResult()

  if (to.name === 'admin' && user) {
    next('/admin/packs')
    return
  }

  if (['auth', 'sign-in', 'sign-up', 'forgot-password'].includes(to.name as string) && user) {
    next({ name: 'games' })
    return
  }

  if (to.matched.some((record) => record.meta.requiresAdmin) && user) {
    if (!idTokenResult.claims.admin) {
      next({ name: 'forbidden' })
      return
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !user) {
    next({ name: 'sign-in' })
    return
  }

  if (to.matched.some((record) => record.meta.requiresAcceptance) && user) {
    const userInfo = await getAdditionnalUserInfo(user.uid)

    if (userInfo === undefined || !userInfo.disclaimerAccepted) {
      next({ name: 'disclaimer-game' })
      return
    }
  }

  next()
})

// TODO: REMOVE // @ts-ignore // @ts-nocheck

export default router
