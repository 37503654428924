import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css' // TODO: Customise CSS
import VueGtag from 'vue-gtag'
import { getAcceptedCookies } from '@/stores/cookies'

import App from './App.vue'
import router from './router'

// * Firebase
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDk9f4A2zbHOL5IuWxS3CdI1a_nnOSdOD8',
  authDomain: 'allez--allez.firebaseapp.com',
  projectId: 'allez--allez',
  storageBucket: 'allez--allez.appspot.com',
  messagingSenderId: '838907708699',
  appId: '1:838907708699:web:6b680b5d336bcca3e9bfbe',
  measurementId: 'G-G369T2BFP0'
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp)
const functions = getFunctions(firebaseApp)

export { db, storage, functions }

import messages from '@intlify/unplugin-vue-i18n/messages'

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'fr',
  fallbackLocale: 'en',
  availableLocales: ['fr', 'en'],
  messages: messages
})

const app = createApp(App)

// global variables
let signedInUser = null
app.config.globalProperties.$signedInUser = signedInUser

const acceptedCookies = getAcceptedCookies()

const gtagEnabled = acceptedCookies ? acceptedCookies.analythics : false

app.use(createPinia())
app.use(
  VueGtag,
  {
    appName: 'Allez Allez',
    enabled: gtagEnabled,
    pageTrackerScreenviewEnabled: true,
    config: { id: 'G-G369T2BFP0' }
  },
  router
)
app.use(router)
app.use(Toast, { position: POSITION.TOP_CENTER })
app.use(i18n)
app.mount('#app')
export default app
