<template>
  <div class="parameters-container" :class="noBackground ? 'no-background' : ''">
    <h2 class="primary">{{ $t('pages.parameters.title') }}</h2>

    <div class="user-card">
      <div class="user-image">
        <i class="fi fi-sr-pencil tooltip" @click="openChangeImageModal">
          <span class="tooltiptext lower">{{ $t('global.edit') }}</span>
        </i>
        <i v-if="user?.photoURL" class="fi fi-ss-trash tooltip" @click="openDeleteImageModal">
          <span class="tooltiptext lower">{{ $t('global.delete') }}</span>
        </i>
        <img
          :src="
            user?.photoURL
              ? user?.photoURL
              : googleUser?.photoURL
              ? googleUser.photoURL
              : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
          "
          alt="User image"
          class="user-image"
        />
      </div>
      <div class="user-infos">
        <div class="user-name">
          <p>
            {{
              user?.displayName
                ? user?.displayName
                : googleUser?.displayName
                ? googleUser?.displayName
                : ''
            }}
          </p>
          <i class="fi fi-sr-pencil tooltip" @click="openChangeDisplayNameModal">
            <span class="tooltiptext lower">{{ $t('global.edit') }}</span>
          </i>
        </div>
        <div class="user-email">
          <p>{{ user?.email ? user.email : googleUser?.email }}</p>
          <i
            v-if="
              googleUser?.providerData[0].providerId !== 'google.com' &&
              googleUser?.providerData[0].providerId !== 'facebook.com'
            "
            class="fi fi-sr-pencil tooltip"
            @click="openChangeEmailModal"
          >
            <span class="tooltiptext lower">{{ $t('global.edit') }}</span>
          </i>
        </div>
        <button
          v-if="
            googleUser?.providerData[0].providerId !== 'google.com' &&
            googleUser?.providerData[0].providerId !== 'facebook.com'
          "
          class="tertiary change-password"
          @click="openResetPasswordModal"
        >
          {{ $t('pages.parameters.change-password') }}
        </button>
      </div>
    </div>

    <div class="other-options">
      <SelectVue
        id="language"
        :title="$t('global.language')"
        :items="languages"
        @update:modelValue="updateLanguage"
        :validation="{ required: true }"
        :touched="true"
        :placeholder="
          (user?.preferredLanguage === 'fr' ? 'Français' : 'English') ||
          ($i18n.locale === 'fr' ? 'Français' : 'English')
        "
      />
      <Switch
        id="email-list"
        :title="$t('pages.parameters.email-list-subscription')"
        :modelValue="emailList"
        @update:modelValue="openEmailListModal"
      />
    </div>

    <button class="tertiary" @click="openSelectCategoriesModal">
      {{ $t('pages.parameters.change-challenges') }}
    </button>

    <div class="useful-links">
      <router-link :to="{ name: 'disclaimer' }" aria-label="Disclaimer">{{
        $t('pages.parameters.disclaimer')
      }}</router-link>
      <a
        :href="locale === 'fr' ? HOW_TO_INSTALL_LINK_FR : HOW_TO_INSTALL_LINK_EN"
        aria-label="How to install"
        class="install"
        target="_blank"
        >{{ $t('pages.parameters.how-to-install') }}
        <i class="fi fi-rr-arrow-up-right-from-square"></i>
      </a>
    </div>

    <Modal
      :fullScreen="selectCategoriesOpen && isMobile()"
      :isOpen="modalIsOpen"
      @close="closeModal"
      :isOutsideClosable="false"
      class="modal"
    >
      <div class="image-uploader" v-if="imageModalIsOpen">
        <FileUpload
          id="user-image-uploader"
          :title="$t('pages.parameters.image-uploader')"
          :validation="{ required: true }"
          :maxFiles="1"
          @update:modelValue="changeImage"
        />
      </div>
      <div class="display-name" v-if="displayNameModalIsOpen">
        <Input
          id="user-display-name"
          :title="$t('pages.parameters.display-name')"
          :placeholder="$t('pages.parameters.display-name-placeholder')"
          :value="nameValue"
          :validation="{ required: true, length: { min: 2, max: 25 } }"
          @update:modelValue="updateName"
        />
      </div>
      <div class="email" v-if="emailModalIsOpen">
        <Input
          type="email"
          id="user-email"
          :title="$t('global.form.email')"
          :placeholder="$t('global.form.email-placeholder')"
          :value="emailValue"
          :validation="{
            required: true,
            regex: { value: EMAIL_REGEX, message: EMAIL_REGEX_MESSAGE }
          }"
          @update:modelValue="updateEmail"
        />
      </div>
      <div class="select-categories" v-if="selectCategoriesOpen">
        <h2>{{ $t('pages.parameters.change-challenges') }}</h2>
        <CategoryToggle
          :disabled="blacklistedCategories.length === 2 && isCategoryToggled(CategoryTypes.GAME)"
          :category="CategoryTypes.GAME"
          :isToggled="isCategoryToggled(CategoryTypes.GAME)"
          :avatarType="AvatarTypes.ORANGE_GREEN"
          @categoryToggled="toggleCategory($event.value, CategoryTypes.GAME)"
        />
        <CategoryToggle
          :disabled="
            blacklistedCategories.length === 2 && isCategoryToggled(CategoryTypes.MOVEMENT)
          "
          :category="CategoryTypes.MOVEMENT"
          :isToggled="isCategoryToggled(CategoryTypes.MOVEMENT)"
          :avatarType="AvatarTypes.BLUE_YELLOW"
          @categoryToggled="toggleCategory($event.value, CategoryTypes.MOVEMENT)"
        />
        <CategoryToggle
          :disabled="blacklistedCategories.length === 2 && isCategoryToggled(CategoryTypes.OUTSIDE)"
          :category="CategoryTypes.OUTSIDE"
          :isToggled="isCategoryToggled(CategoryTypes.OUTSIDE)"
          :avatarType="AvatarTypes.RED_BLUE"
          @categoryToggled="toggleCategory($event.value, CategoryTypes.OUTSIDE)"
        />
        <CategoryToggle
          :disabled="
            blacklistedCategories.length === 2 && isCategoryToggled(CategoryTypes.PHYSICAL)
          "
          :category="CategoryTypes.PHYSICAL"
          :isToggled="isCategoryToggled(CategoryTypes.PHYSICAL)"
          :avatarType="AvatarTypes.PURPLE_YELLOW"
          @categoryToggled="toggleCategory($event.value, CategoryTypes.PHYSICAL)"
        />
        <CategoryToggle
          :disabled="
            blacklistedCategories.length === 2 && isCategoryToggled(CategoryTypes.CONSTRAINT)
          "
          :category="CategoryTypes.CONSTRAINT"
          :isToggled="isCategoryToggled(CategoryTypes.CONSTRAINT)"
          :avatarType="AvatarTypes.BLUE_RED"
          @categoryToggled="toggleCategory($event.value, CategoryTypes.CONSTRAINT)"
        />
        <CategoryToggle
          :disabled="blacklistedCategories.length === 2 && isCategoryToggled(CategoryTypes.PROBLEM)"
          :category="CategoryTypes.PROBLEM"
          :isToggled="isCategoryToggled(CategoryTypes.PROBLEM)"
          :avatarType="AvatarTypes.GREEN_YELLOW"
          @categoryToggled="toggleCategory($event.value, CategoryTypes.PROBLEM)"
        />
        <CategoryToggle
          :disabled="blacklistedCategories.length === 2 && isCategoryToggled(CategoryTypes.TIME)"
          :category="CategoryTypes.TIME"
          :isToggled="isCategoryToggled(CategoryTypes.TIME)"
          :avatarType="AvatarTypes.ORANGE_GREEN"
          @categoryToggled="toggleCategory($event.value, CategoryTypes.TIME)"
        />
        <p class="primary">
          {{ $t('pages.parameters.max-category-blacklisted') }}
        </p>
      </div>
      <div class="delete" v-if="imageDeleteModalIsOpen">
        <h2>{{ $t('pages.parameters.remove-image') }}</h2>
        <p>{{ $t('pages.parameters.remove-image-confirmation') }}</p>
      </div>
      <div class="delete" v-if="resetPasswordModalIsOpen">
        <h2>{{ $t('pages.parameters.reset-password') }}</h2>
        <p>{{ $t('pages.parameters.reset-password-confirmation') }}</p>
      </div>
      <div class="delete" v-if="emailListModalIsOpen">
        <h2>{{ $t('pages.parameters.email-list') }}</h2>
        <p>{{ $t('pages.parameters.remove-email-list-confirmation') }}</p>
      </div>
      <div class="row buttons">
        <button :class="{ primary: !imageDeleteModalIsOpen }" @click="closeModal()">
          {{ $t('global.cancel') }}
        </button>
        <button
          :class="{
            disabled:
              (imageModalIsOpen && !imageStatus) ||
              (displayNameModalIsOpen && !nameStatus) ||
              (emailModalIsOpen && !emailStatus),
            primary: imageDeleteModalIsOpen,
            secondary: !imageDeleteModalIsOpen
          }"
          @click="submitForm"
        >
          {{
            imageDeleteModalIsOpen
              ? $t('global.delete')
              : resetPasswordModalIsOpen
              ? $t('pages.parameters.reset-password')
              : $t('global.confirm')
          }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import Modal from '@/components/common/Global/modal/Modal.vue'
import FileUpload from '@/components/common/form/file-upload/FileUpload.vue'
import Input from '@/components/common/form/input/Input.vue'
import Switch from '@/components/common/form/switch/Switch.vue'
import SelectVue from '@/components/common/form/select/Select.vue'
import CategoryToggle from '@/components/common/category-toggle/CategoryToggle.vue'

import type { User, Status, Item } from '@/types/Types'
import { CategoryTypes, AvatarTypes } from '@/types/Enums'
import {
  EMAIL_REGEX,
  EMAIL_REGEX_MESSAGE,
  HOW_TO_INSTALL_LINK_EN,
  HOW_TO_INSTALL_LINK_FR
} from '@/types/Constants'

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { type User as GoogleUser } from 'firebase/auth'
import authService from '@/stores/auth'
import {
  getAdditionnalUserInfo,
  changeUserImage,
  changeUserDisplayName,
  toggleEmailList,
  changeUserLanguage,
  updateCategoryBlacklist
} from '@/stores/users'
import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n()
import { useToast } from 'vue-toastification'

defineProps({
  noBackground: {
    type: Boolean,
    required: false,
    default: false
  }
})

const toast = useToast()
const router = useRouter()

const googleUser = ref<GoogleUser | null>()
const user = ref<User>()
const modalIsOpen = ref(false)
const imageModalIsOpen = ref(false)
const imageUrl = ref('')
const imageStatus = ref(false)
const imageDeleteModalIsOpen = ref(false)
const displayNameModalIsOpen = ref(false)
const nameValue = ref('')
const nameStatus = ref(false)
const emailModalIsOpen = ref(false)
const emailValue = ref('')
const emailStatus = ref(false)
const resetPasswordModalIsOpen = ref(false)
const emailList = ref(false)
const emailListHasChanged = ref(false)
const emailListModalIsOpen = ref(false)
const selectCategoriesOpen = ref(false)
const blacklistedCategories = ref<string[]>([])

const languages = ref<Item[]>([
  { id: 'en', value: 'en', label: 'English', checked: false },
  { id: 'fr', value: 'fr', label: 'Français', checked: false }
])

const isMobile = () => window.innerWidth <= 768

function openChangeImageModal() {
  modalIsOpen.value = true
  imageModalIsOpen.value = true
}

function openChangeDisplayNameModal() {
  modalIsOpen.value = true
  displayNameModalIsOpen.value = true
}

function openChangeEmailModal() {
  modalIsOpen.value = true
  emailModalIsOpen.value = true
}

function openDeleteImageModal() {
  modalIsOpen.value = true
  imageDeleteModalIsOpen.value = true
}

function openResetPasswordModal() {
  modalIsOpen.value = true
  resetPasswordModalIsOpen.value = true
}

function openEmailListModal(value: boolean) {
  emailList.value = value
  if (user.value?.emailList) {
    modalIsOpen.value = true
    emailListModalIsOpen.value = true
  } else switchEmailList()
}

function openSelectCategoriesModal() {
  modalIsOpen.value = true
  selectCategoriesOpen.value = true
}

function toggleCategory(isToggled: boolean, category: string) {
  if (blacklistedCategories.value.length === 2 && !isToggled) return

  isToggled
    ? blacklistedCategories.value.splice(blacklistedCategories.value.indexOf(category), 1)
    : blacklistedCategories.value.push(category)
}

function isCategoryToggled(category: string) {
  return !blacklistedCategories.value.includes(category)
}

async function closeModal() {
  if (!emailListHasChanged.value && emailListModalIsOpen.value) {
    emailList.value = !emailList.value
  }
  modalIsOpen.value = false
  imageModalIsOpen.value = false
  displayNameModalIsOpen.value = false
  emailModalIsOpen.value = false
  imageDeleteModalIsOpen.value = false
  resetPasswordModalIsOpen.value = false
  emailListModalIsOpen.value = false
  imageStatus.value = false
  nameStatus.value = false
  emailStatus.value = false
}

async function changeImage(fileURL: string) {
  imageUrl.value = fileURL
  imageStatus.value = imageUrl.value !== ''
}

function updateName(name: string, status: Status) {
  nameValue.value = name
  nameStatus.value = status.valid
}

function updateEmail(email: string, status: Status) {
  emailValue.value = email
  emailStatus.value = status.valid
}

async function submitForm() {
  if (!googleUser.value) return

  if (displayNameModalIsOpen.value && nameStatus.value) {
    await changeUserDisplayName(googleUser.value.uid, nameValue.value)
    user.value = await getAdditionnalUserInfo(googleUser.value.uid)
    toast.success(t('pages.parameters.display-name-changed'))
  } else if (emailModalIsOpen.value && emailStatus.value) {
    authService.updateEmail(emailValue.value, toast, t)
    user.value = await getAdditionnalUserInfo(googleUser.value.uid)
  } else if (imageModalIsOpen.value && imageStatus.value) {
    user.value = await getAdditionnalUserInfo(googleUser.value.uid)
    await changeUserImage(googleUser.value.uid, imageUrl.value, user.value?.photoURL)
    toast.success(t('pages.parameters.image-changed'))
  } else if (imageDeleteModalIsOpen.value) {
    await changeUserImage(googleUser.value.uid, '', user.value?.photoURL)
    user.value = await getAdditionnalUserInfo(googleUser.value.uid)
    toast.success(t('pages.parameters.image-removed'))
  } else if (resetPasswordModalIsOpen.value && googleUser.value.email) {
    authService.sendResetPasswordEmail(googleUser.value.email, toast, t)
    toast.success(t('pages.parameters.reset-password-email-sent'))
  } else if (emailListModalIsOpen.value) {
    await switchEmailList()
  } else if (selectCategoriesOpen.value) {
    await updateCategoryBlacklist(googleUser.value.uid, blacklistedCategories.value)
    user.value = await getAdditionnalUserInfo(googleUser.value.uid)
    toast.success(t('pages.parameters.categories-updated'))
  }

  closeModal()
}

async function switchEmailList() {
  if (!googleUser.value || !user.value) return
  await toggleEmailList(googleUser.value.uid, !user.value?.emailList)
  user.value = await getAdditionnalUserInfo(googleUser.value.uid)
  emailListHasChanged.value = true
}

let languageHasBeenUpdated = false

function updateLanguage(value: Item[]) {
  if (languageHasBeenUpdated) {
    languageHasBeenUpdated = false
    return
  }
  const checkedItem = value.find((item) => item.checked)
  if (!checkedItem) return
  locale.value = checkedItem.value
  localStorage.setItem('locale', locale.value)
  if (!googleUser.value) return
  changeUserLanguage(googleUser.value?.uid, checkedItem.value)
  toast.success(t('pages.parameters.language-updated'))
  languageHasBeenUpdated = true
  languages.value = value
}

onMounted(async () => {
  googleUser.value = await authService.getSignedInUser()
  if (!googleUser.value) {
    router.push('/login')
    return
  }
  user.value = await getAdditionnalUserInfo(googleUser.value.uid)
  emailList.value = user.value?.emailList ?? false
  blacklistedCategories.value = user.value?.categoryBlacklist ?? []
})
</script>

<style scoped lang="scss">
@import './Parameters.scss';
</style>
